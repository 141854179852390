import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/normal',
    name: 'normal',
    meta: {
      title: '普通预约'
    },
    component: () => import('../views/Normal.vue')
  },
  // {
  //   path: '/visit',
  //   name: 'visit',
  //   meta: {
  //     title: '专业预约'
  //   },
  //   component: () => import('../views/Visit.vue')
  // },
  {
    path: '/appoints-query',
    name: 'appointsQuery',
    meta: {
      title: '订单查询'
    },
    component: () => import('../views/AppointsQuery')
  },
  {
    path: '/appoints',
    name: 'appoints',
    meta: {
      title: '我的订单'
    },
    component: () => import('../views/Appoints')
  },
  {
    path: '/appoints/:no',
    name: 'appoint',
    meta: {
      title: '预约详情'
    },
    component: () => import('../views/Appoint')
  },
  {
    path: '/qa',
    name: 'qa',
    meta: {
      title: '常见问题'
    },
    component: () => import('../views/QA.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    meta: {
      title: '游客须知'
    },
    component: () => import('../views/Guide.vue')
  },
  {
    path: '*',
    name: 'home',
    meta: {
      title: process.env.VUE_APP_TITLE
    },
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
