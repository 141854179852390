<template>
    <div id="app">
        <transition name="slide-left">
            <router-view ref="main-view"/>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style lang="scss">
    html, body {
        font-size: 14px;
        height: 100%;
        background: #CAD39F;
    }

    #app {
        width: 100%;
        box-sizing: border-box;
        padding: 25px 0 25px;
    }

    .container {
        margin: auto;
    }

    @media (min-width: 768px) {
        .container {
            width: 1000px;
        }
    }

    img {
        max-width: 100%;
    }

    .btn-primary {
        background-color: #496EA2!important;
        border-color: #496EA2!important;
        padding-left: 48px!important;
        padding-right: 48px!important;
    }

    .btn-primary-plain {
        border-color: #496EA2!important;
        color: #496EA2!important;
    }

    .van-cell {
        .van-cell__label {
            flex: 2;
        }
        .van-cell__value {
            white-space: nowrap;
            flex: 3;
            color: #000000;
        }
        .van-cell {
            font-size: 15px;
        }
        .mtitle {
            font-size: 14px;
            white-space: nowrap;
        }
        .mlabel {
            font-size: 10px;
            margin-top: -8px;
            margin-bottom: -5px;
        }
    }

    .panel {
        padding: 0 20px;

        .tit {
            width: 150px;
            margin: auto;
            position: relative;

            img {
                margin: 0 auto -24px;
            }

            span {
                top: 10px;
                display: block;
                position: absolute;
                text-align: center;
                width: 100%;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 3px;
            }

        }

        .content {
            border-top: #496EA2 6px solid;
            background: #FFFFFF;
            padding: 30px 20px 30px;
        }

        @media (min-width: 768px) {
            .tit {
                width: 300px;

                img {
                    margin: 0 auto -40px;
                }

                span {
                    top: 24px;
                    font-size: 24px;
                }
            }
            .content {
                padding: 50px 20px 40px;
            }
        }
    }
</style>
