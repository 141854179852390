import axios from 'axios'
import router from '../router'
import NProgress from 'nprogress'
import { Toast } from 'vant'

export default {
  install (Vue, Options) {
    let toast = (title, message, variant, options) => {
      Toast.fail(message)
    }
    const instance = axios.create({
      headers: {
        'content-type': 'application/json;charset=UTF-8'
      },
      baseURL: location.protocol + '//' + process.env.VUE_APP_BASE_URL,
      timeout: 10000,
      withCredentials: false
    })

// 添加请求拦截器
    instance.interceptors.request.use(config => {
      NProgress.start()
      return config
    }, error => {
      // 请求错误时做些事
      return Promise.reject(error)
    })

// 添加响应拦截器
    instance.interceptors.response.use(response => {
      NProgress.done()
      // 对响应数据做些事
      if (response.status === 200) {
        if (response.data && response.data.code === '0') {
          return response.data
        } else if (response.data && response.data.code === '1004') {
          router.push({ name: '404' }).catch(error => console.error(error))
        } else if (response.data && response.data.code !== '0') {
          toast('错误', response.data.msg, 'danger')
          return response.data
        }
      }
    }, error => {
      NProgress.done()
      let status = error.response.status
      if (status >= 400 && status <= 499) {
        router.push({ name: '404' }).catch(error => console.error(error))
      } else if (status >= 500) {
        toast('错误', '服务器繁忙,请稍后重试', 'danger')
      } else {
        toast('错误', '请求错误,请检查网络是否可用', 'danger')
      }
      return Promise.reject(error.response.data)
    })

    window.addEventListener('online', () => {
      toast('上线了', '恭喜, 网络恢复了', 'success')
    })

    window.addEventListener('offline', () => {
      toast('掉线了', '糟糕, 网络断开了', 'danger')
    })

    Vue.prototype.$http = instance
  }

}
