import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './plugins/http.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'normalize.css'
import { Dialog, Toast } from 'vant'
import 'vant/lib/toast/style'
import 'vant/lib/dialog/style'

Vue.use(Toast)
Vue.use(Dialog)
Vue.use(axios)

Vue.mixin({
    data () {
        return {
            env: process.env,
            locks: {}
        }
    },
    methods: {
        lock: function (key, timeout = 3) {
            const that = this
            if (that.locks[key] === true) {
                console.warn(key + ' is locked!')
                return false
            } else {
                that.locks[key] = true
                setTimeout(() => {
                    delete that.locks[key]
                }, timeout * 1000)
                return true
            }
        },
        unlock: function (key) {
            const that = this
            delete that.locks[key]
        },
        encodeCardNo: function (cardNo) {
            return cardNo.replace(/^(.{3})(?:\d+)(.{4})$/, '$1******$2')
        }
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', to.fullPath])
        }
    }
    NProgress.start()
    next()
})

router.afterEach((to, from, next) => {
    NProgress.done()
})
